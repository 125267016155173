import React from "react";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection({ image1, image2, image3, image4, slogan, text }) {

  const bgImages = [
    image1,
    image2,
    image3,
    image4,
  ]
  return (
    <>
      <div className="pic-wrapper">

        {
          bgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }


        <div className="z-[9] relative w-4/5 mx-auto md:h-[80vh] h-[110vh]">
          <div className="w-full h-full pb-[5%] md:pb-[10%] flex flex-col justify-end items-center">
            <h1 className="text-white text-center">
              {slogan}
            </h1>
            <p className="text-white text-center"> {text}</p>
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
